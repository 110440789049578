import textCaseHelper from "../../../../utils/textCaseHelper";

export default {
  name: 'marking/enums/scheduleTask',
  enumTextCase: textCaseHelper.AS_IS,
  // externalTextCase: textCaseHelper.AS_IS,
  enums: [
    'list-codes',
    'list-documents',
    'search-company-products',
    'update-company-codes',
    'process-auto-balance-codes',
    'update-nk-products',
  ],
  enumsExtraData: {
    // TODO: формат параметров для каждой задачи
  }
};
