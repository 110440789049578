import ResourceIcon from '@material-ui/icons/Print';
import printRequestChild from './printRequestChild';
import printDialog from '../../modules/printDialog';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';


const name = 'marking/print-request';
const precessName = 'printer';
const apiBasePath = `/bp/processes/${precessName}/`;

const itemActions = [
  {
    name: `resources.${name}.actions.print`,
    isDisabled: ({ record }) => record.status === 'draft',
    getTransitionUrl: ({ record }) => printDialog.getTransitionUrl({
      params: {
        document_id: record.id,
      }
    }),
  }
];

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  childResource: printRequestChild,
  showExternalStatus: false,
  showExternalId: false,
  allowCreateWithGenerator: false,
  hasConnectedCodes: false,
  allowShowCodesAction: false,
  schema: {
    type: 'object',
    properties: {
      'data.name': {
        type: 'string',
      },
      'data.is_reprint': {
        type: 'boolean',
      },
    }
  },
  itemActions,
  createFields: [
    'data.name',
    'data.is_reprint',
  ],
});
