import request from '../../network/request';

export default ({ apiBasePath }) => ({
  name: 'documents.actions.renew.name',
  isDisabled: ({ record }) => record.direction === 'In' || record.status === 'draft' || record.status === 'completed',
  handler: async ({ record }) => {
    const path = `${apiBasePath}${record.id}/renew/`;
    const urlParams = {};

    let confirmForce = false;
    if (['request_created', 'processing', 'checking_document_status'].includes(record.status) === 'request_created') {
      confirmForce = window.confirm('Документ находится в процессе обработки. Сброс документа не прервет фоновую обработку если она в данный момент активна. Сбросить документ?');
      if (!confirmForce) {
        return {
          notification: {
            type: 'cancel',
            message: `documents.actions.renew.cancelMessage`
          }
        };
      }
    }
    if (record.external_id) {
      confirmForce = window.confirm('Документ имеет ID во внешней системе, после сброса продолжать работу с внешним документом по этому ID будет невозможно. Сбросить документ?');
      if (!confirmForce) {
        return {
          notification: {
            type: 'cancel',
            message: `documents.actions.renew.cancelMessage`
          }
        };
      }
    }

    if (confirmForce) {
      urlParams.force = true;
    }

    let response = null;
    try {
      response = await request({
        path,
        method: 'POST',
        urlParams,
      });
    } catch(e) {}

    if (response && response.ok) {
      return {
        notification: {
          type: 'success',
          message: `documents.actions.renew.successMessage`
        }
      }
    }

    return {
      notification: {
        type: 'error',
        message: `documents.actions.renew.failureMessage`
      }
    }
  }
})
