import ResourceIcon from '@material-ui/icons/Input';
import serialNumberType from '../../../enums/serialNumberType';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';
import codeTemplateId from '../../../enums/codeTemplateId';
import ProductsSelect from './ProductsSelect';
import cisType from '../../../enums/cisType';


const name = 'marking/order-child';
const parentResourceName = 'marking/order';
const apiBasePath = '/bp/processes/order/{{parent_id}}/children/';
export default docChildConfigCreator({
  name,
  apiBasePath,
  parentResourceName,
  icon: ResourceIcon,
  itemToString: x => x.gtin || `#${x.id}`,
  apiResolve: {
    mapping: {
      outputItemAfter: (output) => {
        if (output && output.data && output.data.serial_numbers) {
          output.data.serial_numbers = output.data.serial_numbers
            .split('\n')
            .map(x => x.trim())
            .filter(x => x.length > 0);
        } else {
          output.data.serial_numbers = null;
        }
        return output;
      },
      inputItemAfter: (input) => {
        if (input && input.data) {
          if (input.data.serial_numbers) {
            input.data.serial_numbers = input.data.serial_numbers.join('\n');
          } else {
            input.data.serial_numbers = null;
          }
        }
        return input;
      },
    }
  },
  schema: {
    type: 'object',
    properties: {
      'data.gtin': {
        type: 'string',
        componentInput: ProductsSelect,
        validation: {
          required: true,
        },
      },
      'data.quantity': {
        type: 'number',
        defaultValue: 0,
        validation: {
          min: 1,
          required: true,
        }
      },
      'data.serial_number_type': {
        type: 'string',
        componentInput: serialNumberType.Input,
        componentField: serialNumberType.Field,
        defaultValue: 'OPERATOR',
        validation: {
          required: true,
        },
      },
      'data.serial_numbers': {
        type: 'string',
        defaultValue: null,
        options: {
          multiline: true,
          showCondition: (data) => data.data && data.data.serial_number_type === 'SELF_MADE',
        }
      },
      'data.template_id': {
        type: 'number',
        componentInput: codeTemplateId.Input,
        validation: {
          required: true,
        }
      },
      'data.cis_type': {
        type: 'string',
        defaultValue: null,
        componentInput: cisType.Input,
        componentField: cisType.Field,
        options: {
          forProductGroups: ['clothes', 'perfumery', 'electronics', 'otp', 'water', 'milk'],
        },
      },
      'data.exporter_taxpayer_id': {
        type: 'string',
        defaultValue: null,
        options: {
          forProductGroups: ['clothes', 'perfumery', 'shoes', 'tires', 'electronics', 'otp'],
        },
      },
      'data.exp_date': {
        type: 'date',
        defaultValue: null,
        options: {
          forProductGroups: ['milk'],
        },
      },
      'data.exp_date_72': {
        type: 'dateTime',
        defaultValue: null,
        options: {
          forProductGroups: ['milk'],
        },
      },
    }
  },
  listFields: [
    'data.gtin',
    'data.quantity',
    'data.serial_number_type',
  ],
});
