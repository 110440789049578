import React, { PureComponent } from 'react';
import { addField, AutocompleteInput } from 'react-admin';
import request from '../../../../../network/request';

class ProductAutocomplete extends PureComponent {

  state = {
    value: '',
    choices: []
  }

  componentDidMount() {
    this.loadProducts();
  }

  loadProducts = async () => {
    let response = null;
    try {
      response = await request({
        path: `/bp/product/products/`,
        method: 'GET',
        urlParams: {
          page_size: 20,
        }
      })
    } catch (e) {
      console.error(e);
    }

    let items = [];
    if (response === null || !response.ok) {
      // if (response !== null) {
      //   error = response.data
      // }
    } else {
      items = response.data.results.map(x => ({
        id: x.gtin,
        name: `${x.gtin} - ${x.name}`
      }));
    }

    this.setState({
      choices: items,
    });
  }

  onInput = (value) => {
    this.setState({
      value,
    })
  }

  render() {
    const {
      ...restProps
    } = this.props;
    let {
      value,
      choices,
    } = this.state;

    if (value !== '' && choices.findIndex(x => x.id === value) === -1) {
      choices = [
        { id: value, name: value },
        ...choices,
      ];
    }
    
    return (
      <AutocompleteInput
        {...restProps}
        setFilter={this.onInput}
        choices={choices}
        translateChoice={false}
      />
    );
  }
}

export default addField(ProductAutocomplete);