import React, { Component } from 'react';

import { Card } from '@material-ui/core';
import {
  Title,
  SimpleForm,
} from '../../components/Admin';

class FormPage extends Component {
  state = {
    loadError: null,
    loadInProgress: true,
    
    saveError: null,
    saveInProgress: false,

    initialData: null,
  }
  
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const {
      loadData = null,
    } = this.props;

    if (loadData === null) {
      return null;
    }
    this.setState({
      loadInProgress: true,
    });

    let data = null;
    let error = null;
    try {
      data = await loadData();
    } catch(e) {
      error = e;
    }

    this.setState({
      loadError: error,
      loadInProgress: false,
      defaultData: data,
    })
  }

  saveData = async (data) => {
    const {
      saveData = null,
    } = this.props;

    if (saveData === null) {
      return null;
    }
    this.setState({
      saveInProgress: true,
    });

    let error = null;
    try {
      await saveData(data);
    } catch(e) {
      error = e;
    }

    this.setState({
      saveError: error,
      saveInProgress: false,
    });

    if (error === null) {
      return data;
    }
  }
  
  render() {
    const {
      pageTitle = null,
      children,
    } = this.props;
    const {
      // loadError,
      // loadInProgress,
      defaultData,
    } = this.state;

    if (defaultData === null) {
      return null;
    }

    return (
      <div style={{ marginTop: '1em' }}>
        {pageTitle && (
          <Title title={pageTitle} />
        )}
        <Card>
          <SimpleForm
            initialValues={defaultData}
            variant="standard"
            margin="normal"
            save={this.saveData}
          >
            {children}
          </SimpleForm>
        </Card>
      </div>
    );
  }
}

export default FormPage;