import deepmerge from 'deepmerge';
import defaultConfig from "./defaultConfig";
import parseEnv from "../utils/parseEnv";
import { asyncTimeout } from '../utils';

type AppConfigData = {
  environment?: string;
  configUrl: string;
  authBaseUrl: string;
  authRealm: string;
  authClientId: string;
  apiBaseUrl: string;
  productGroups: string[];
  displayProductGroups: string[];
  sentryDSN?: string;
  sentryEnvironment?: string;
  apiDateFormat: string;
  apiDateTimeFormat: string;
  ui: {
    enableMetrics: boolean;
    enableNotifications: boolean;
  }
}

const uiConfigStorageKey = 'ui_config';

class AppConfig {
  loadedState = null;
  // @ts-ignore
  data: AppConfigData = {};

  constructor() {
    this.setData(defaultConfig);
  }
  
  setData = (data) => {
    const newData = {
      ...data,
    };
    if (typeof newData.productGroups === 'string') {
      newData.productGroups = newData.productGroups.split(',').map(x => x.trim());
    }
    if (typeof newData.displayProductGroups === 'string') {
      newData.displayProductGroups = newData.displayProductGroups.split(',').map(x => x.trim());
    }

    
    this.data = deepmerge(this.data, newData);
  }

  updateUIConfig = (ui: AppConfigData['ui']) => {
    this.setData({ ui });

    const uiConfigForStore = deepmerge(this.data.ui, ui);
    for (let key in uiConfigForStore) {
      if (uiConfigForStore[key] === defaultConfig.ui[key]) {
        delete uiConfigForStore[key];
      }
    }
    localStorage.setItem(uiConfigStorageKey, JSON.stringify(uiConfigForStore));
  }

  loadConfig = async () => {
    this.loadedState = 'loading';
    const response = await fetch(this.data.configUrl);
    // @ts-ignore
    const newData: AppConfigData = {};
    let isError = false
    if (response.ok) {
      const text = await response.text();
      const data = parseEnv(text);

      if (data.ENVIRONMENT) {
        newData.environment = data.ENVIRONMENT;
      }

      if (data.AUTH_BASE_URL) {
        newData.authBaseUrl = data.AUTH_BASE_URL;
      }
      if (data.AUTH_REALM) {
        newData.authRealm = data.AUTH_REALM;
      }
      if (data.AUTH_CLIENT_ID) {
        newData.authClientId = data.AUTH_CLIENT_ID;
      }

      if (data.API_BASE_URL) {
        newData.apiBaseUrl = data.API_BASE_URL;
      }
      if (data.PRODUCT_GROUPS) {
        newData.productGroups = data.PRODUCT_GROUPS;
      }
      if (data.DISPLAY_PRODUCT_GROUPS) {
        newData.displayProductGroups = data.DISPLAY_PRODUCT_GROUPS;
      }

      if (data.SENTRY_DSN) {
        newData.sentryDSN = data.SENTRY_DSN;
      }
      if (data.SENTRY_ENVIRONMENT) {
        newData.sentryEnvironment = data.SENTRY_ENVIRONMENT;
      }
    } else {
      isError = true;
    }

    const uiConfigInStorage = localStorage.getItem(uiConfigStorageKey);
    if (uiConfigInStorage) {
      try {
        const ui = JSON.parse(uiConfigInStorage);
        newData.ui = ui;
      } catch (e) {}
    }


    this.setData(newData);

    if (isError) {
      this.loadedState = 'loaded';
      return;
    }
    this.loadedState = 'error';
  }
}

export default new AppConfig();
