import deepmerge from 'deepmerge';
import { createMuiTheme } from '@material-ui/core';
import baseTheme from '../baseTheme';
import palette from './palette';


export default createMuiTheme(deepmerge(baseTheme.mui, {
  palette,
  overrides: {
    RaMenuItemLink: {
      active: {
        background: `${palette.primary.dark} !important`,
        color: palette.primary.contrastText,
      },
      icon: {
        color: 'currentColor'
      }
    },
    RaFilter: {
      button: {
        '& .MuiButton-textPrimary': {
          color: palette.text.primary,
        },
        '& .MuiButton-textPrimary:hover': {
          backgroundColor: palette.action.hover,
        },
      }
    },
    RaSaveButton: {
      button: {
        '& .MuiCircularProgress-colorPrimary': {
          color: palette.primary.contrastText,
        }
      }
    },


    MuiAppBar: {
      colorSecondary: {
        backgroundColor: '#212121',
      },
    },
  }
}));
