import createFormPage from '../../../../utils/admin/createFormPage';
import locales from './locales';
import config from './config';
import appConfig from '../../../../appConfig';

export default {
  ...config,
  locales,
  PageComponent: createFormPage({
    ...config,
    pageTitle: `pages.${config.name}.name`,
    schema: {
      type: 'object',
      properties: {
        enableMetrics: {
          type: 'boolean',
        },
        enableNotifications: {
          type: 'boolean',
        },
      }
    },
    fields: [
      { name: 'enableMetrics', label: `pages.${config.name}.fields.enableMetrics` },
      { name: 'enableNotifications', label: `pages.${config.name}.fields.enableNotifications` },
    ],
    loadData: async () => {
      return appConfig.data.ui;
    },
    saveData: async (data) => {
      appConfig.updateUIConfig(data);
      return data;
    }
  })
}
