import createFormPage from '../../../../utils/admin/createFormPage';
import request from '../../../../network/request';
import locales from './locales';
import config from './config';
import { fillUrl } from '../../../../utils/url';

export default {
  ...config,
  locales,
  PageComponent: createFormPage({
    ...config,
    pageTitle: `pages.${config.name}.name`,
    schema: {
      type: 'object',
      properties: {
        username: {
          type: 'string',
          defaultValue: '',
        },
        password: {
          type: 'string',
          defaultValue: '',
        },
        client_token: {
          type: 'string',
        },
        unique_string: {
          type: 'string',
        },
        oms_id: {
          type: 'string',
        },
        oms_connection_id: {
          type: 'string',
        }
      }
    },
    fields: [
      { name: 'unique_string', label: `pages.${config.name}.fields.unique_string` },
      { name: 'oms_id', label: `pages.${config.name}.fields.oms_id` },
      { name: 'oms_connection_id', label: `pages.${config.name}.fields.oms_connection_id` },
      { name: 'client_token', label: `pages.${config.name}.fields.client_token` },
    ],
    loadData: async () => {
      let response = null;
      try {
        response = await request({
          path: fillUrl('/cm/company/company/{{companyId}}/credentials/get/'),
          method: 'GET',
        })
      } catch (e) {
        console.error(e);
      }

      if (response === null || !response.ok) {
        throw new Error(response.data);
      } else {
        return response.data;
      }
    },
    saveData: async (data) => {
      let response = null;
      try {
        response = await request({
          path: fillUrl('/cm/company/company/{{companyId}}/credentials/create/'),
          body: data,
          contentType: 'json',
          method: 'POST',
        })
      } catch (e) {
        console.error(e);
      }

      if (response === null || !response.ok) {
        throw new Error(response.data);
      } else {
        return response.data;
      }
    }
  })
}
