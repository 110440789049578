import ResourceIcon from '@material-ui/icons/LocalShipping';
import shipmentOutChild from './shipmentOutChild';
import turnoverType from '../../enums/turnoverType';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import CompanyDataInput from '../../../../components/CompanyDataInput';
import acceptShippment from '../../modules/acceptShippment';


const name = 'marking/shipment';
const precessName = 'out_out';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  showDirection: true,
  childResource: shipmentOutChild,
  allowUpdateAction: true,
  schema: {
    type: 'object',
    properties: {
      'data.document_num': {
        type: 'string',
        defaultValue: null,
      },
      'data.document_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.receiver_inn': {
        type: 'string',
        defaultValue: null,
      },
      'data.receiver': {
        type: 'string',
        defaultValue: null,
      },
      'data.sender_inn': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
      },
      'data.sender': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'name',
        },
      },
      'data.owner_inn': {
        type: 'string',
        defaultValue: null,
      },
      'data.owner': {
        type: 'string',
        defaultValue: null,
      },
      'data.turnover_type': {
        type: 'string',
        componentInput: turnoverType.Input,
        componentField: turnoverType.Field,
        defaultValue: null,
      },
      'data.transfer_date': {
        type: 'date',
        defaultValue: null,
      },
    }
  },
  itemActions: [
    {
      name: `resources.${name}.actions.accept`,
      isDisabled: ({ record }) => !(record.status === 'wait_acceptance' && record.direction === 'In'),
      getTransitionUrl: ({ record }) => acceptShippment.getTransitionUrl({
        params: {
          document_id: record.id,
        }
      }),
    }
  ],
  createFields: [
    'data.document_num',
    'data.document_date',
    'data.receiver_inn',
    'data.receiver',
    'data.sender_inn',
    'data.sender',
    'data.owner_inn',
    'data.owner',
    'data.turnover_type',
    'data.transfer_date',
  ],
});
