import React, { Fragment } from 'react';
import {
  Datagrid,
  List,
  Filter,
  BulkDeleteButton,
  BulkExportButton,
  Pagination,
} from '../Admin';
import withStyles from '@material-ui/core/styles/withStyles';
import ProxyContainer from '../ProxyContainer';
import exporter from '../../utils/exporter';
import EditButton from '../EditButton';
import ShowButton from '../ShowButton';
import { CloneButton } from '../CloneButton';
import ResourceActionButton from '../ResourceActionButton';
import ResourceActionsMenu from '../ResourceActionsMenu';
import checkPermissions from '../../utils/checkPermissions';
import NotFoundPage from '../../pages/NotFoundPage';
import ResourceListToolbar from './ResourceListToolbar';
import useExtendedFiltersIsActive from '../../hooks/useExtendedFiltersIsActive';
import ResourceBulkActionButton from '../ResourceBulkActionButton';


const listStyles = {
  controlsContainer: {
    minHeight: 36,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

export const ResourceBulkActionButtons = ({ actions, allowExport, ...props }) => (
  <Fragment>
    {props.children}
    {actions && actions.map(action => (
      action.component ? (
        <action.component {...action.props} {...props} />
      ) : (
          <ResourceBulkActionButton action={action} {...props} />
        )
    ))}
    {allowExport && (
      <BulkExportButton
        {...props}
        color="default"
        exporter={exporter}
      />
    )}
    {props.allowDelete && (
      <BulkDeleteButton undoable={false} {...props} />
    )}
  </Fragment>
);

export const ResourceListFilter = (props) => (
  <Filter {...props} variant="outlined" color="default">
  </Filter>
);

// const EmptyPage = props => (
// <pre>{JSON.stringify(props, null, 2)}</pre>
// )


const ResourceListPage = (props) => {
  let {
    classes,
    children,
    allowEdit = false,
    allowShow = false,
    allowClone = false,
    allowCreate = false,
    allowDelete = false,
    allowExport = false,
    rowActions = [],
    expand = undefined,
    filters = undefined,
    extendedFilters = undefined,
    actions = undefined,
    bulkActions = undefined,
    defaultSort = { field: 'id', order: 'DESC' },
    permissions,
    rowStyle,
    rowClick,
    extended,
    resource,
    basePath,
    ...restProps
  } = props;
  const isExtended = useExtendedFiltersIsActive(resource);

  const hasControls = allowEdit || allowShow || rowActions.length > 0;
  const allowExtended = extendedFilters !== undefined;

  if (allowExtended && isExtended) {
    filters = extendedFilters;
  }

  let rowInlineActions = rowActions.filter(x => x.inline);
  let rowMenuActions = rowActions.filter(x => !x.inline);

  if (permissions === undefined) {
    return null;  // LOADING
  }

  if (!checkPermissions('view', permissions)) {
    return (
      <NotFoundPage />
    );
  }

  return (
    <List
      actions={<ResourceListToolbar actions={actions} allowExport={allowExport} allowExtended={allowExtended} allowCreate={allowCreate && checkPermissions('create', permissions)} />}
      filters={filters}
      bulkActionButtons={<ResourceBulkActionButtons actions={bulkActions} allowExport={allowExport} allowDelete={allowDelete && checkPermissions('delete', permissions)} />}
      exporter={exporter}
      perPage={10}
      sort={defaultSort}
      pagination={<Pagination />}
      // hasCreate={allowCreate}
      // empty={<EmptyPage />}
      empty={false}
      hasCreate={false}
      syncWithLocation
      resource={resource}
      basePath={basePath}
      {...restProps}
    >
      <Datagrid
        expand={expand}
        rowStyle={rowStyle}
        rowClick={rowClick}
      >
        {children}
        {hasControls && (
          <ProxyContainer className={classes.controlsContainer}>
            {(allowEdit && checkPermissions('edit', permissions)) && (
              <EditButton color="default" />
            )}
            {(allowShow && checkPermissions('viewOne', permissions)) && (
              <ShowButton color="default" />
            )}
            {(allowClone && checkPermissions('create', permissions)) && (
              <CloneButton color="default" />
            )}
            {rowInlineActions.map(action => (
              action.component ? (
                <action.component {...action.props} />
              ) : (
                  <ResourceActionButton action={action} />
                )
            ))}
            {rowMenuActions.length > 0 && (
              <ResourceActionsMenu
                actions={rowMenuActions}
              />
            )}
          </ProxyContainer>
        )}
      </Datagrid>
    </List>
  )
}

export default withStyles(listStyles)(ResourceListPage);
