import ResourceIcon from '@material-ui/icons/Label';
import request from '../../../../network/request';
import GuidField from '../../../../components/GuidField';

const name = 'marking/product';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/bp/product/products/',
  },
  itemToString: x => `${x.gtin} - ${x.name}` || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        validation: {
          required: true,
        }
      },
      gtin: {
        type: 'string',
        validation: {
          required: true,
        }
      },
    }
  },
  allowList: true,
  allowCreate: false,
  allowEdit: false,
  allowShow: true,
  allowDelete: false,
  showInMenu: true,
  listActions: [{
    name: `resources.${name}.actions.loadProducts.name`,
    handler: async () => {
      const path = `/bp/product/search/`;

      let response = null;
      try {
        response = await request({
          path,
          method: 'POST',
        });
      } catch (e) { }

      if (response && response.ok) {
        return {
          notification: {
            type: 'success',
            message: `resources.${name}.actions.loadProducts.successMessage`
          }
        }
      }

      return {
        notification: {
          type: 'error',
          message: `resources.${name}.actions.loadProducts.failureMessage`
        }
      }
    }
  }],
  showFields: [
    'name',
    'gtin',
    'vendor_code',
    'data.brand',
    'data.fullName',
    'tn_ved_code',
    'data.inn',
  ],
  listFields: [
    { name: 'id', component: GuidField },
    'gtin',
    'name',
    'vendor_code',
  ],
  listFilters: [
    'gtin',
  ]
};
