import appConfig from "../appConfig";

export default () => ({
  baseResources: [
    'common/company',
    'common/user',
    'settings/stock',
    'settings/label-templates',
    'settings/encoding-server',
    'settings/userRole',
    'settings/member',
    'settings/member-roles',
    (appConfig.data.ui.enableNotifications && 'settings/notification-group-webhooks'),
    (appConfig.data.ui.enableNotifications && 'settings/notification-group-telegram'),
    (appConfig.data.ui.enableNotifications && 'settings/notification-group-email'),
    (appConfig.data.ui.enableNotifications && 'settings/notification-group'),
    'settings/scheduled-task',
    'report/task',
    'report/logs',
    'report/workflow',
    (appConfig.data.ui.enableNotifications && 'report/notification'),
    'marking/product',
    'marking/marking-codes',
    'marking/order',
    'marking/print-request',
    'marking/input-self-made',
    'marking/input-import',
    'marking/input-fts',
    'marking/input-from-contract',
    'marking/aggr',
    'marking/reaggr',
    'marking/disaggr',
    'marking/shipment',
    'marking/shipment-cancel',
    'marking/acceptance',
    'marking/remarking',
    'marking/returns',
    'marking/transfer-local',
    'marking/receipt',
    'settings/ean-info',
    'marking/task',
    'marking/logs',
    'marking/documents',
    'marking/autobalance-codes',
    'settings/productGroup',

    'common/company',
    'settings/stock',
    'settings/label-templates',
    'settings/encoding-server',
    'report/task',
    'report/logs',
    'report/workflow',
    'marking/product',
    'marking/marking-codes',
    'marking/marking-code-history',
    'marking/marking-code-document',
    'marking/order',
    'marking/order-child',
    'marking/print-request',
    'marking/print-request-child',
    'marking/input-import',
    'marking/input-import-child',
    'marking/input-fts',
    'marking/input-fts-child',
    'marking/input-self-made',
    'marking/input-self-made-child',
    'marking/input-from-contract',
    'marking/input-from-contract-child',
    'marking/cancel-codes',
    'marking/cancel-codes-child',
    'marking/aggr',
    'marking/reaggr',
    'marking/reaggr-child',
    'marking/disaggr',
    'marking/disaggr-child',
    'marking/shipment',
    'marking/shipment-child',
    'marking/shipment-cancel',
    'marking/acceptance',
    'marking/acceptance-child',
    'marking/remarking',
    'marking/remarking-child',
    'marking/returns',
    'marking/returns-child',
    'marking/transfer-local',
    'marking/transfer-local-child',
    'marking/custom-local',
    'marking/custom-local-child',
    'marking/receipt',
    'marking/receipt-child',
    'settings/ean-info',
    'marking/task',
    'marking/logs',
  ],
  pgResources: {
    shoes: [
      'marking/input-remains',
      'marking/input-remains-child',
      'marking/input-from-individual',
      'marking/input-from-individual-child',
      'marking/remains-description',
      'marking/remains-description-child',
    ],
    clothes: [
      'marking/input-remains',
      'marking/input-remains-child',
      'marking/input-from-individual',
      'marking/input-from-individual-child',
      'marking/remains-description',
      'marking/remains-description-child',
    ],
    perfumery: [
      'marking/input-remains',
      'marking/input-remains-child',
      'marking/input-from-individual',
      'marking/input-from-individual-child',
      'marking/remains-description',
      'marking/remains-description-child',
    ],
    milk: [
      'marking/usage-report',
      'marking/usage-report-child',
      'marking/rollout-report',
      'marking/rollout-report-child',
    ],
    water: [
      'marking/usage-report',
      'marking/usage-report-child',
    ]
  }
})
