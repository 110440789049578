import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        IN_PROGRESS: 'In progress',
        CHECKED_OK: 'Checked ok',
        CHECKED_NOT_OK: 'Checked not ok',
        PROCESSING_ERROR: 'Processing error',
        UNDEFINED: 'Undefined',
        CANCELLED: 'Canceled',
        ACCEPTED: 'Accepted',
        WAIT_ACCEPTANCE: 'Wait acceptance',
        WAIT_PARTICIPANT_REGISTRATION: 'Wait participant registration',

        DRAFT: 'Draft',
        PENDING: 'Pending',
        CHECK: 'Check',
        READY_TO_SEND: 'Ready to send',
        REJECTED: 'Rejected',
        SENT: 'Sent',
        PROCESSED: 'Processed',
        WAIT_FOR_CONTINUATION: 'Wait for continuation',
      },
    }
  }
}
