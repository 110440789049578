import deepmerge from 'deepmerge';
import 'moment/locale/ru';
import russianMessages from 'ra-language-russian';

export default deepmerge(russianMessages, {
    ra: {
        navigation: {
            // page_range_info: '%{offsetBegin}-%{offsetEnd} из %{total}',
            page_range_info: '%{offsetBegin}-%{offsetEnd}',
        }
    },
    list: {
      simpleSearch: 'Обычный поиск',
      extendedSearch: 'Расширенный поиск',
    },
    actions: {
        create: 'Сохранить',
        createAndGoToEdit: 'Сохранить и продолжить редактирование',
        createAndGoToShow: 'Сохранить и перейтии к просмотру',
        createAndGoToCreate: 'Сохранить и добавить другой объект',
        save: 'Сохранить',
        saveAndGoToEdit: 'Сохранить и продолжить редактирование',
        saveAndGoToShow: 'Сохранить и перейтии к просмотру',
    },
    pagination: {
      actions: {
        getCount: {
          name: 'Показать кол-во',
          successMessage: 'Общее кол-во для данной выборки: %{total}',
          failureMessage: 'Запрос на кол-во обработан с ошибкой',
        },
        getCountNoCache: {
          name: 'Показать кол-во без кеша (медленно)',
          successMessage: 'Общее кол-во для данной выборки: %{total}',
          failureMessage: 'Запрос на кол-во обработан с ошибкой',
        },
      },
    },
    pos: {
        title: 'Сервис Маркировки',
        language: 'Язык',
        theme: {
            name: 'Тема',
            light: 'Светлая',
            dark: 'Темная',
        },
        dashboard: {
            welcome: {
                title: 'Добро пожаловать',
                subtitle: "",
            },
        },
        login: {
            hint: 'Marking hub'
        }
    },
    widgets: {
        metrics: {
            rangeInputLabel: 'Период',
            chartSetInputLabel: 'Тип выборки',
            ranges: {
                '30m': '30 минут',
                '1h': '60 минут',
                '2h': '2 часа',
                '4h': '4 часа',
                '1d': '24 часа',
            },
        },
        lastDocs: {
            title: 'Последние документы'
        },
        lastNotifications: {
            title: 'Уведомления',
            showAll: 'Смотреть все',
        },
        documentsMetrics: {
            title: 'Статистика по документам (Beta)',
            sets: {
                amounts: 'Количество',
                events: 'События',
            },
        },
        codesMetrics: {
            title: 'Статистика по кодам (Beta)',
            sets: {
                amounts: 'Количество',
                events: 'События',
            },
        },
        documentsCount: {
            title: 'Документы'
        },
        codesCount: {
            title: 'Коды маркировки'
        },
        productsCount: {
            title: 'Товары'
        },
    },
    resources: {
    },
    companySelect: {
        label: 'Компания',
        unknownCompany: 'Неизвестная компания',
        editCompanies: 'Управление компаниями',
    },
    stockSelect: {
        label: 'Склад',
        allStocks: 'Все склады',
        unknownStock: 'Неизвестный склад',
        editStocks: 'Управление складами',
    },
    themeSelect: {
        tooltip: 'Сменить тему'
    },
    languageSelect: {
        tooltip: 'Сменить язык'
    },
    menu: {
        serviceApiDoc: 'API документация',
        profile: 'Профиль',
        uiConfig: 'Интерфейс',
    },
    validationErrors: {
        inn: 'Невалидный ИНН'
    },
    documents: {
        addChild: 'Добавить',
        tabs: {
            documentStatus: 'Документ',
            documentInfo: 'Данные документа',
            documentChildren: 'Позиции документа',
        },
        fields: {
            'extra.name': 'Имя документа',
        },
        actions: {
            send: {
                name: 'Отправить документ',
                successMessage: 'Документ отправлен',
                failureMessage: 'Ошибка при запросе на отправку',
            },
            refresh: {
                name: 'Обновить документ',
                successMessage: 'Запрос на обновление документа отправлен',
                failureMessage: 'Ошибка при запросе на обновление документа',
            },
            renew: {
                name: 'Сбросить документ',
                successMessage: 'Запрос на сброс документа отправлен',
                failureMessage: 'Ошибка при запросе на сброс документа',
                cancelMessage: 'Запрос отменен',
            },
            bulkSend: {
                name: 'Отправить документы',
                successMessage: 'Документы отправлен',
                failureMessage: 'При запросе на отправку документов произошла ошибка',
            },
            bulkUpdate: {
              name: 'Обновить документы',
              successMessage: 'Запрос на обновление документов отправлен',
              failureMessage: 'При запросе на обновление документов произошла ошибка',
            },
            docDownloadJSON: {
                name: 'Скачать JSON',
            },
            showLogs: 'Показать логи',
            showCodes: 'Показать коды',
            createNextDoc: 'Новый документ',
        },
        childGenerator: {
          sectionTitle: 'Автозаполнение позиций',
          fields: {
            toggle: 'Использовать автоматическую выборку кодов',
            query: 'MHQL Запрос',
            amount: 'Количество',
            amountHint: 'Укажите -1 чтобы выбрать все найденный коды',
          },
        },
    },
    common: {
        id: 'ID',
        document_id: 'ID',
        create: 'Создать',
        dashboard: 'Главная',
        status: 'Статус',
        created: 'Дата создания',
        updated: 'Дата обновления',
        external_id: 'Внешний ID',
        external_status: 'Внешний статус',
        direction: 'Направление',
        name: 'Имя',
        info: 'Информация',
        quantity: 'Количество',
        default: 'По-умолчанию',
        slug: 'Псевдоним',
        description: 'Описание',
        inn: 'ИНН',
        doctype: 'Тип документа',
        vsd_number: 'Идентификатор ВСД',

        tnved_10: 'ТНВЭД (10)',
        tnved: 'ТНВЭД',
        color: 'Цвет',
        product_size: 'Размер',
        production_country: 'Страна производства',

        primary_document_type: 'Вид первичного документа',
        primary_document_number: 'Номер первичного документа',
        primary_document_date: 'Дата первичного документа',
        primary_document_custom_name: 'Наименование первичного документа',

        certificate_document: 'Вид документа, подтверждающего соответствие',
        certificate_document_date: 'Дата документа, подтверждающего соответствие',
        certificate_document_number: 'Номер документа, подтверждающего соответствие',

        query: 'MHQL Запрос',
        unknownError: 'Неизвестная ошибка',
    },
    productGroups: {
        clothes: {
            name: 'Одежда',
        },
        shoes: {
            name: 'Обувь',
        },
        tobacco: {
            name: 'Табак',
        },
        perfumery: {
            name: 'Парфюм',
        },
        tires: {
            name: 'Шины',
        },
        electronics: {
            name: 'Фототехника',
        },
        water: {
          name: 'Вода',
        },
        pharma: {
            name: 'Лекарства',
        },
        milk: {
            name: 'Молоко',
        },
        bicycle: {
            name: 'Велосипеды',
        },
        wheelchairs: {
            name: 'Кресла-коляски',
        },
    },
    errors: {
        'BP-2-003': 'Редактирование недоступно. Документ отправлен',
        'BP-2-004': 'Редактирование недоступно. Документ обрабатывается'
    },
    companyStatusMenu: {
      label: 'Статус',
      encodingRunner: {
        label: 'ЭЦП Сервер',
        initialazing: 'Проверка',
        connected: 'Сервер подключен',
        disconnected: 'Сервер не подключен',
        error: 'Ошибка запроса',
      },
    },
    dateRange: {
      'Apply': 'Применить',
      'Today': 'Сегодня',
      'Yesterday': 'Вчера',
      'This Week': 'Эта неделя',
      'Last Week': 'Прошлая неделя',
      'This Month': 'Этот месяц',
      'Last Month': 'Прошлый месяц',
      'days up to today': 'дней до сегодня',
      'days starting today': 'дней после сегодня',
    },
    LogField: {
      request: 'HTTP Запрос',
      requestUrl: 'URL',
      requestMethod: 'Метод',
      requestBody: 'Тело запроса',
      response: 'Ответ',
      responseStatusCode: 'Код ответа',
      responseBody: 'Тело ответа',
      messageJson: 'Сообщение (JSON)',
      message: 'Сообщение',
    },
})
