import ResourceIcon from '@material-ui/icons/FindInPage';
import { GET_LIST } from '../../../../components/Admin';
import GuidField from '../../../../components/GuidField';
import documentProcessType from '../../enums/documentProcessType';
import documentStatus from '../../enums/documentStatus';
import getDocumentUrlByDoctype from '../../../../utils/documents/getDocumentUrlByDoctype';
import history from '../../../../core/history';
import documentDirectionType from '../../enums/documentDirectionType';
import documentExternalStatus from '../../enums/documentExternalStatus';
import QLInputDocuments from '../../../../components/QLInputDocuments';

const name = 'marking/documents';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/bp/document/document/',
    pathListExtended: "/bp/document/search/",
    urlParams: {
      is_process: true,
    },
    idField: 'document_id',
    changeConfig: (type, params, requestConfig) => {
      if (type === GET_LIST && params && params.filter && params.filter.mh_query) {
        const limit = requestConfig.urlParams.limit;
        const offset = requestConfig.urlParams.offset;
        const body = {
          query: params.filter.mh_query,
          limit,
          offset,
        };
        requestConfig = {
          ...requestConfig,
          body,
          path: '/bp/document/search/',
          method: 'POST',
          contentType: 'json',
          urlParams: {},
        }
      }
      return requestConfig;
    }
  },
  group: 'settings',
  itemToString: x => x.name || `#${x.document_id}`,
  schema: {
    type: 'object',
    properties: {
      created__lte: {
        type: 'date',
      },
      created__gte: {
        type: 'date',
      },
      modified__lte: {
        type: 'date',
      },
      modified__gte: {
        type: 'date',
      },
      last_es_update__lte: {
        type: 'date',
      },
      last_es_update__gte: {
        type: 'date',
      },
    }
  },
  allowList: true,
  allowCreate: false,
  allowEdit: false,
  allowShow: false,
  allowDelete: true,
  showInMenu: true,
  listDefaultSort: { field: 'created', order: 'DESC' },
  listFields: [
    { name: 'id', component: GuidField },
    { name: 'direction', component: documentDirectionType.Field },
    { name: 'doctype', component: documentProcessType.Field },
    { name: 'created', type: 'date' },
    { name: 'status', component: documentStatus.Field },
    { name: 'external_id', type: 'string', component: GuidField, },
    { name: 'external_status', type: 'string', component: documentExternalStatus.Field, },
  ],
  listFilters: [
    { name: 'id', type: 'string' },
    { name: 'status', component: documentStatus.Input },
    { name: 'external_id', type: 'string' },
    { name: 'direction', component: documentDirectionType.Input },
    { name: 'extra_name', type: 'string' },
    { name: 'code', type: 'string' },
    { name: 'mh_query' },
    // { name: 'created__lte', type: 'date' },
    // { name: 'created__gte', type: 'date' },
    // { name: 'modified__lte', type: 'date' },
    // { name: 'modified__gte', type: 'date' },
    // { name: 'last_es_update__lte', type: 'date' },
    // { name: 'last_es_update__gte', type: 'date' },
  ],
  listExtendedFilters: [
    { name: "query", component: QLInputDocuments, alwaysOn: true },
  ],
  listRowClickAction: (id, url, item) => {
    const path = getDocumentUrlByDoctype(item.doctype, item.document_id);
    if (path === null) {
      alert('Unknown document type');
      return;
    }
    history.push(path);
  },
};
