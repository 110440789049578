const defaultConfig = {
  environment: process.env.REACT_APP_ENVIRONMENT || null,
  configUrl: process.env.REACT_APP_CONFIG_URL || '/config.json',
  authBaseUrl: process.env.REACT_APP_AUTH_BASE_URL || '',
  authRealm: process.env.REACT_APP_AUTH_REALM || '',
  authClientId: process.env.REACT_APP_AUTH_CLIENT_ID || '',
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '',
  productGroups: process.env.REACT_APP_PRODUCT_GROUPS || '',
  displayProductGroups: process.env.REACT_APP_DISPLAY_PRODUCT_GROUPS || '*',
  sentryDSN: process.env.REACT_APP_SENTRY_DSN || null,
  sentryEnvironment: process.env.REACT_APP_SENTRY_ENVIRONMENT || null,
  apiDateFormat: 'YYYY-MM-DD',
  apiDateTimeFormat: 'YYYY-MM-DD HH:mm',
  ui: {
    enableMetrics: false,
    enableNotifications: false,
  }
}

export default defaultConfig;