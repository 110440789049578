import { MarkingOrderIcon } from '../../../../components/ui/Icon';
import itemCreateMethodType from '../../enums/itemCreateMethodType';
import releaseMethodType from '../../enums/releaseMethodType';
import getOrderCodesCSV from './getOrderCodesCSV';
import textCaseHelper from '../../../../utils/textCaseHelper';
import printStatus from '../../enums/printStatus';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import orderChild from './orderChild';
import CompanyDataInput from '../../../../components/CompanyDataInput';


const name = 'marking/order';
const precessName = 'order';
const apiBasePath = `/bp/processes/${precessName}/`;

const itemActions = [
  {
    name: `resources.${name}.actions.getReplyCSV`,
    handler: getOrderCodesCSV,
  },
];

export default docConfigCreator({
  name,
  icon: MarkingOrderIcon,
  apiBasePath,
  precessName,
  showExternalStatus: false,
  allowCreateWithGenerator: false,
  childResource: orderChild,
  schema: {
    type: 'object',
    properties: {
      'data.contact_person': {
        type: 'string',
        componentInput: CompanyDataInput,
        validation: {
          required: true,
        },
        options: {
          companyField: 'info.contact_person',
        }
      },
      'data.release_method_type': {
        type: 'string',
        componentInput: releaseMethodType.Input,
        componentField: releaseMethodType.Field,
        validation: {
          required: true,
        }
      },
      'data.remains_available': {
        type: 'boolean',
        defaultValue: false,
        options: {
          forProductGroups: ['shoes', 'clothes'],
          showCondition: (data) => data.data && data.data.release_method_type === 'REMAINS',
        },
      },
      'data.remains_import': {
        type: 'boolean',
        defaultValue: false,
        options: {
          forProductGroups: ['shoes', 'clothes'],
          showCondition: (data) => data.data && data.data.release_method_type === 'REMAINS',
        },
      },
      'data.create_method_type': {
        type: 'string',
        componentInput: itemCreateMethodType.Input,
        componentField: itemCreateMethodType.Field,
        validation: {
          required: true,
        },
      },
      'data.production_order_id': {
        type: 'string',
        defaultValue: null,
      },
      'data.contract_number': {
        type: 'string',
        defaultValue: null,
      },
      'data.contract_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.print_status': {
        type: 'string',
        defaultValue: textCaseHelper.pascalCase('AVAILABLE'),
        componentInput: printStatus.Input,
        componentField: printStatus.Field,
        options: {
          allowEmpty: false,
          textCase: textCaseHelper.PASCAL_CASE,
          availableValues: ['AVAILABLE', 'NOT_AVAILABLE']
        },
      }
    }
  },
  itemActions,
  createFields: [
    'data.contact_person',
    'data.release_method_type',
    'data.remains_available',
    'data.remains_import',
    'data.create_method_type',
    'data.production_order_id',
    'data.contract_number',
    'data.contract_date',
    'data.print_status',
  ],
  tabStatusLabel: `resources.${name}.tabs.status`,
  tabInfoLabel: `resources.${name}.tabs.info`,
  tabChildrenLabel: `resources.${name}.tabs.products`,
});
