import React from 'react';
import compose from 'recompose/compose';
import JSONView from 'react-json-view'
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';

const styles = {
  root: {
    fontSize: 12,
  },
  text: {
    whiteSpace: 'pre-line',
  }
};

const JsonViewer = ({ classes, theme, value }) => {
  let isJson = true;
  if (typeof value === 'string') {
    try {
      value = JSON.parse(value)
    } catch(e) {
      isJson = false;
    }
  }
  if (isJson && typeof value === 'object') {
    return (
      <div className={classes.root}>
        <JSONView
          src={value}
          theme={theme === 'dark' ? 'flat' : 'rjv-default'}
          collapsed
          style={{ backgroundColor: 'transparent' }}
        />
      </div>
    );
  }

  return (
    <Typography component="span" variant="body2" className={classes.text}>
      {value.toString()}
    </Typography>
  )
};

const mapStateToProps = state => ({
  theme: state.app.theme,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(JsonViewer);;
