import { createMuiTheme } from '@material-ui/core';
import palette from './palette';
import animation from './animation';
import indents from './indents';
import queries from './queries';
import typography from './typography';

window.createMuiTheme = createMuiTheme;
export default createMuiTheme({
  palette: palette,
  queries,
  typography,
  animation,
  indents,
  shape: {
    borderRadius: 2,
  },
  overrides: {
    RaFormInput: {
      input: {
        width: 500,
        maxWidth: '100%',
      }
    },
    RaAppBar: {
      menuButton: {
        marginLeft: 5,
      }
    },
    RaListToolbar: {
      toolbar: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap-reverse',
      }
    },
    RaFilterForm: {
      form: {
        minHeight: '0',
        marginTop: '0',
        marginBottom: '10px',
        flexShring: '1',
      },
    },
    RaMenuItemLink: {
      active: {
        background: `${palette.primary.main} !important`,
        color: palette.primary.contrastText,
      }
    },
    RaBulkActionsToolbar: {
      title: {
        alignItems: 'center',
        marginLeft: '-6px', // Для выравния крестика по уровню чекбоксов
      },
      toolbar: {
        color: palette.primary.contrastText,
      },
    },
    RaSimpleFormIterator: {
      index: {
        flexShrink: 0,
      }
    },


    MuiAppBar: {
      root: {
        boxShadow: 'none',
        padding: '5px 0',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      },
      colorSecondary: {
        backgroundColor: '#fff',
      },
    },
    MuiToolbar: {
      gutters: {
        flexShrink: 0, // For LIST filters
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          // background: 'rgba(8, 76, 255, 0.08) !important'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      }
    },
    MuiTableCell: {
      head: {
        paddingTop: '14px !important',
        paddingBottom: '8px !important',
      },
    },
    MuiIconButton: {
      sizeSmall: {
        padding: 8
      }
    },
    RaAutocompleteSuggestionItem: {
      root: {
        overflow: 'visible',
      }
    }
  },
});
