import download from 'downloadjs';
import request from '../../network/request';

export default ({ apiBasePath, order = 10, name = 'documents.actions.docDownloadJSON.name' }) => ({
  name,
  order,
  handler: async ({ record }) => {const path = `${apiBasePath}${record.id}/?with_children=true`;
    
    let response = null;
    try {
      response = await request({
        path,
        method: 'GET',
      });
    } catch(e) {}

    if (response && response.ok && response.data) {
      const full_record = response.data;
      const name = (full_record.extra && full_record.extra.name ? full_record.extra.name : full_record.document_id);
      const prefix = (full_record.status === 'failed' ? 'ERROR_' : 'REPLY_');
      download(JSON.stringify(full_record, null, 2), `${prefix}${name}.json`, null);
    }
  }
})