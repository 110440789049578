import appCore from "../appCore";
import auth from "../auth";

export default async (options = {}) => {
  let {
    accessToken = null,
    companyId = null,
    productGroup = null,
    stockId = null,
  } = options;
  const headers = {};

  if (accessToken === null) {
    // accessToken = localStorage.getItem('accesstoken') || null;
    accessToken = await auth.getToken();
  }
  if (accessToken !== null) {
      headers['X-Auth-Token'] = `Bearer ${accessToken}`;
  }


  if (companyId === null) {
    companyId = appCore.currentUrlParams.companyId || null;
  }
  if (companyId !== null) {
      headers['X-Company-Id'] = companyId;
  }


  if (productGroup === null) {
    productGroup = appCore.currentUrlParams.productGroup || null;
  }
  if (productGroup !== null) {
      headers['X-Product-Group'] = productGroup;
  }


  if (stockId === null) {
    stockId = appCore.currentUrlParams.stockId || null;
    if (stockId === 'all') {
      stockId = null;
    }
  }
  if (stockId !== null) {
      headers['X-Stock-Id'] = stockId;
  }

  return headers;
};
