import download from 'downloadjs';
import rest from "../../../../network/rest";

export default async ({ record }) => {
  const codes = await rest.getList('marking/marking-codes', {
    filter: {
      documents: record.id
    },
    pagination: {
      page: 1,
      perPage: 1000,
    }
  });
  const positions = await rest.getManyReference('marking/order-child', {
    target: 'parent_id',
    id: record.id,
    pagination: {
      page: 1,
      perPage: 1000,
    }
  });

  if (codes.data && positions.data) {
    const totalCount = codes.data.length;
    let requestedCount = 0;

    positions.data.forEach(x => {
      requestedCount += x.data.quantity;
    });

    if ((totalCount > 0 && totalCount === requestedCount) || window.confirm(`Founded ${totalCount} codes from ${requestedCount} requested. Do you want to continue?`)) {
      const csvRows = codes.data.map(x => x.code.replace(/^[^\d]/, '')); // TODO: fix backend bug
      const csv = csvRows.join('\n');
      let fileName = `REPLY_ORDER_${record.id}`;
      if (record.extra && record.extra.name) {
        fileName = `REPLY_${record.extra.name}`;
      }
      download(csv, `${fileName}.csv`, null);
    } else {
      const subOrders = [];
      positions.data.forEach(pos => {
        const subOrder = {
          ...pos,
          codes: [],
          success: false,
        };

        subOrder.codes = codes.data.filter(x => x.gtin === pos.gtin);
        subOrder.success = subOrder.quantity === subOrder.codes.length;

        subOrders.push(subOrder);
      });
    }
  } else {
    return {
      notification: {
        type: 'error',
        message: 'Request failed'
      }
    }
  }
};
