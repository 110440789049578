import getResourceUrl from "../admin/getResourceUrl";

export default ({ precessName, order = 30, name = 'documents.actions.showLogs' } = {}) => ({
  name,
  order,
  isDisabled: ({ record }) => {
    return record.status === 'draft';
  },
  getUrl: ({ record }) => record && getResourceUrl({
    resourceName: 'marking/task',
    pageType: 'LIST',
    params: {
      filter: {
        document_id: record.id,
      }
    }
  })
})
