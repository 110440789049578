import ResourceIcon from '@material-ui/icons/CheckCircle';
import GuidField from '../../../../components/GuidField';
import JsonField from '../../../../components/JsonField';


const name = 'report/workflow';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/workflow-service/task/workflow/',
    disableSorting: true,
    mapping: {
      inputItem: (item) => { 
        let base_operator_name = null;
        try {
          if (item.workflow_structure.length > 0) {
            const baseOperator = item.workflow_structure[0];
            if (Array.isArray(baseOperator) && typeof baseOperator[0] === 'string') {
              base_operator_name = baseOperator[0];
            }
          }
        } catch(e) {}
        return {
          ...item,
          base_operator_name,
        };
      },
    }
  },
  itemToString: x => `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      
    }
  },
  allowList: true,
  allowCreate: false,
  allowEdit: false,
  allowShow: false,
  allowDelete: false,
  showInMenu: true,
  group: 'reports',
  listExpandFields: [
    { name: 'task_info', component: JsonField, options: { fullWidth: true }},
    { name: 'run_structure', component: JsonField, options: { fullWidth: true }},
    { name: 'workflow_structure', component: JsonField, options: { fullWidth: true }},
  ],
  listFilters: [],
  listFields: [
    { name: 'id', component: GuidField, sortable: false, },
    { name: 'created_at', type: 'dateTime', sortable: false, },
    { name: 'base_operator_name', type: 'string', sortable: false, },
  ],
};