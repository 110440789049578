import {
  ShoeIcon,
  ClothesIcon,
  PerfumeIcon,
  TireIcon,
  MilkIcon,
  BicycleIcon,
  WheelchairIcon,
  ElectronicIcon,
  WaterIcon,
  // PharmaIcon,
  // TobaccoIcon,
} from "../components/ui/Icon"

export const allProductGroups = [{
  name: 'shoes',
  icon: ShoeIcon,
  templateId : 1,
}, {
  name: 'clothes',
  icon: ClothesIcon,
  templateId : 10,
}, {
  name: 'perfumery',
  icon: PerfumeIcon,
  templateId : 9,
}, {
  name: 'tires',
  icon: TireIcon,
  templateId : 7,
}, {
  name: 'milk',
  icon: MilkIcon,
  templateId : 20,
}, {
  name: 'water',
  icon: WaterIcon,
  templateId : 16,
}, {
  name: 'bicycle',
  icon: BicycleIcon,
  templateId : 11,
}, {
  name: 'wheelchairs',
  icon: WheelchairIcon,
  templateId : 12,
}, {
  name: 'electronics',
  icon: ElectronicIcon,
  templateId : 8,
// }, {
//   name: 'pharma',
//   icon: PharmaIcon,
//   templateId : 5,
// }, {
//   name: 'tobacco',
//   icon: TobaccoIcon,
//   templateId : 3,
}];
