import React, { PureComponent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {
  translate as translateRA,
} from 'react-admin';
import compose from '../../../../utils/compose';

@compose(
  translateRA,
)
class PaginationFunctionsMenuItem extends PureComponent {
  onClick = () => {
    const {
      onClick,
      action,
    } = this.props;
    if (onClick) {
      onClick(action);
    }
  }
  render() {
    let {
      action,
      record,
      disabled = false,
      translate,
    } = this.props;

    const nextProps = {

    }

    if (action.isDisabled && typeof action.isDisabled === 'function') {
      disabled = action.isDisabled({ record });
    }

    return (
      <MenuItem
        {...nextProps}
        disabled={disabled}
        onClick={this.onClick}
      >
        {translate(action.name)}
      </MenuItem>
    );
  }
}

export default PaginationFunctionsMenuItem;
