import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Marking code |||| Marking codes',
      actions: {
        refreshAll: {
          name: 'Fetch codes',
          successMessage: 'Request sent',
          failureMessage: 'Request failed',
        },
        copyCIS: {
          name: 'Copy CIS',
          successMessage: 'CIS copied to clipboard',
        },
        copyFullCode: {
          name: 'Copy Full Code',
          successMessage: 'Full code copied to clipboard',
        },
        refresh: {
          name: 'Fetch',
          successMessage: 'Request sent',
          failureMessage: 'Request failed',
        },
        bulkUpdate: {
          name: 'Fetch',
          successMessage: 'Request sent',
          failureMessage: 'Request failed',
        },
        bulkCreateDoc: {
          name: 'Create new document',
        },
      },
      tabs: {
        info: 'Information',
        documents: 'Documents',
        history: 'Changes history',
        children: 'Child codes',
      },
      fields: {
        'gtin': 'GTIN',
        'serial': 'Serial',
        'status': 'Status',
        'external_status': 'External status',
        'order_id': 'IExternal order ID',
        'cis': 'UIT/UITU',
        'emission_date': 'Emission date',
        'emission_type': 'Emission type',
        'last_es_update': 'Last date update',
        'code': 'Full KM',
        'itemprinterstatus.status': 'Print status',
        'stock': 'Stock',
        'package_type': 'Package type',
        'parent': 'Parent code',
        'additional_status': 'Additional status',
        'printer_item.status': 'Printer status',

        'parent__isnull': 'In package',
        'documents': 'By document',
      }
    }
  }
}
