import moment from 'moment';
import qs from 'qs';
import errorTracker from '../utils/errorTracker';
import getAuthHeaders from './getAuthHeaders';
import getServiceUrl from './getServiceUrl';
import parseServerError from './parseServerError';


type RequestOptions = {
  body?: any;
  urlParams?: { [key:string]: any };
  path?: string;
  method?: string;
  headers?: { [key:string]: string };
  apiService?: string;
  warehouseId?: string;
  accessToken?: string;
  responseType?: string;
  contentType?: string;
}


export const dateToParam = (data, format) => {
  let result = data;
  let m = moment(data, "DD.MM.YYYY");
  if (m.isValid()) {
    result = m.format(format);
  }
  m = moment(data);
  if (m.isValid()) {
    result = m.format(format);
  }
  return result;
};

export default async (options: RequestOptions) => {
  let {
    body = null,
    urlParams = null,
    path = '/',
    method = 'GET',
    headers = {},
    apiService = null,
    warehouseId = null,
    accessToken = null,
    responseType = 'json',
    contentType = null,
  } = options;

  const authHeaders = await getAuthHeaders({
    warehouseId,
    accessToken,
  });

  headers = {
    ...headers,
    ...authHeaders,
  };

  let baseUrl = getServiceUrl(apiService);

  let fullUrl = `${baseUrl}${path}`;
  if (urlParams !== null) {
    fullUrl = `${fullUrl}?${qs.stringify(urlParams, { arrayFormat: 'comma' })}`;
  }

  if (contentType !== null && body !== null) {
    if (contentType === 'json' && typeof body === 'object') {
      if (typeof body === 'object') {
        body = JSON.stringify(body);
      }
      headers['content-type'] = 'application/json'
    } else if (contentType === 'form' && typeof body === 'object') {
      const formData = new FormData();
      for (let key in body) {
        formData.append(key, body[key]);
      }

      body = formData;
    }
  }

  const requestConfig = {
    method,
    body,
    headers,
  };

  const response = await fetch(fullUrl, requestConfig);

  const result = {
    response,
    ok: response.ok,
    status: response.status,
    data: null,
    parsedError: null,
  }
  if (response.ok) {
    try {
      if (responseType === 'json') {
        result.data = await response.json()
      }
      if (responseType === 'text') {
        result.data = await response.text()
      }
      if (responseType === 'blob') {
        result.data = await response.blob()
      }
    } catch(e) {}
  } else {
    let errorBody = null;
    if (response.body) {
      try {
        errorBody = await response.json()
      } catch(e) {
        console.log('e', e);
        errorBody = await response.text()
      }
    }
    result.parsedError = parseServerError(response, errorBody);
    errorTracker.captureRequestError(result);
  }


  return result;
}
