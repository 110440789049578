import React, { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslate } from 'react-admin';
import TransitionLink from '../TransitionLink';

const ActionMenuItem = memo((props) => {
  let {
    action,
    record,
    disabled = false,
    onClick,
  } = props;

  const translate = useTranslate();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(action);
    }
  }, [onClick, action])

  const nextProps = {

  }
  if (action.getUrl) {
    nextProps.component = Link;
    nextProps.to = action.getUrl({ record });
  } else if (action.getTransitionUrl) {
    nextProps.component = TransitionLink;
    nextProps.transitionTo = action.getTransitionUrl({ record });
  }

  if (action.isDisabled && typeof action.isDisabled === 'function') {
    disabled = action.isDisabled({ record });
  }

  return (
    <MenuItem
      {...nextProps}
      disabled={disabled}
      onClick={handleClick}
    >
      {translate(action.name)}
    </MenuItem>
  );
});

export default ActionMenuItem;
