import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товар |||| Товары',
      actions: {
        loadProducts: {
          name: 'Выгрузить товары',
          successMessage: 'Запрос на выгрузку товаров успешно отправлен',
          failureMessage: 'При запросе на выгрузку товаров произошла ошибка',
        },
      },
      fields: {
        name: 'Наименование',
        gtin: 'GTIN',
        vendor_code: 'Код поставщика',
        'data.brand': 'Бренд',
        'data.fullName': 'Полное наименование',
        'tn_ved_code': 'ТН ВЭД',
        'data.inn': 'ИНН',
      }
    }
  }
}
