import { registerLocales } from './i18n';
import { registerResource as registerRestProviderResource } from './network/rest';
import AppStorage from './utils/appStorage';
import { allProductGroups } from './utils/productGroups';
import appConfig from './appConfig';

class AppCore {
  productGroups = [];
  resources = [];
  services = [];
  pages = [];
  menuItems = [];
  routes = [];
  currentUrlParams = {};
  storage = new AppStorage();

  init = () => {
    let displayProductGroups = allProductGroups;
    if (!appConfig.data.displayProductGroups.includes('*')) {
      displayProductGroups = displayProductGroups.filter(x => appConfig.data.productGroups.includes(x.name) || appConfig.data.displayProductGroups.includes(x.name));
    }
    this.productGroups = displayProductGroups
      .map(x => {
        x.active = appConfig.data.productGroups.includes(x.name);
        return x;
      })
      .sort((a, b) => b.active - a.active);
  }

  updateCurrentUrlParams = (params = {}) => {
    this.storage.updateCompanyId(params.companyId);
    this.currentUrlParams = params;
  };

  registerMenuItem = (item) => {
    this.menuItems.push(item);
    // #TODO: add sort order
  }

  registerLocales = (locales) => registerLocales(locales)

  registerRestProviderResource = (data) => registerRestProviderResource(data)

  registerResource = (res, service = null) => {
    res.serviceName = service === null ? null : service.name;
    this.resources.push(res);

    if (res.locales) {
      this.registerLocales(res.locales);
    }
    if (res.restProviderResolve) {
      this.registerRestProviderResource(res.restProviderResolve);
    }
    if (res.menuConfig) {
      const serviceName = service === null ? null : service.name;
      this.registerMenuItem({
        serviceName,
        ...res.menuConfig,
      });
    }
  }

  registerResources = (resources, service = null) => {
    resources.forEach((x) => this.registerResource(x, service));
  }

  registerPage = (page, service = null) => {
    this.pages.push(page);

    this.routes.push({
      exact: true,
      path: `/${page.name}`,
      component: page.PageComponent,
    });
    if (page.locales) {
      this.registerLocales(page.locales);
    }
    if (page.menuConfig) {
      const serviceName = service === null ? null : service.name;
      this.registerMenuItem({
        serviceName,
        ...page.menuConfig,
      });
    }
  }

  registerPages = (pages, service = null) => {
    pages.forEach((x) => this.registerPage(x, service));
  }

  registerService = (service) => {
    if (service.locales) {
      this.registerLocales(service.locales);
    }
    if (service.pages) {
      this.registerPages(service.pages, service);
    }
    if (service.resources) {
      this.registerResources(service.resources, service);
    }

    this.services.push(service);
  }
}

export default new AppCore();
