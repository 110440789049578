import resourceConfig from '../config';

export default {
  pages: {
    [resourceConfig.name]: {
      name: 'Настройка доступов компании',
      fields: {
        unique_string: 'Идентификатор сертификата подписи',
        oms_id: 'OMS идентификатор',
        oms_connection_id: 'OMS идентификатор соединения',
        client_token: 'OMS токен устройства',
      }
    }
  }
}
