import request from '../../network/request';

export default ({ apiBasePath, order = 10, name = 'documents.actions.bulkSend.name' }) => ({
  name,
  order,
  handler: async (options) => {
    const {
      selectedIds = [],
    } = options;

    if (selectedIds.length === 0) {
      return;
    }

    if (!window.confirm(`Подтвердите отправку документов (${selectedIds.length} шт.)`)) {
      return;
    }

    let response = null;
    try {
      response = await request({
        path: `${apiBasePath}execute/`,
        method: 'POST',
        contentType: 'json',
        body: {
          ids: selectedIds,
        }
      });
    } catch (e) { }

    if (response && response.ok) {
      return {
        notification: {
          type: 'success',
          message: 'documents.actions.bulkSend.successMessage'
        }
      }
    }

    return {
      notification: {
        type: 'error',
        message: 'documents.actions.bulkSend.failureMessage'
      }
    }
  }
})
