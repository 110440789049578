import request from '../../network/request';

export default ({ apiBasePath, order = 10, name = 'documents.actions.send.name' }) => ({
  name,
  order,
  isDisabled: ({ record }) => {
    if (record.meta) { // TEMP
      if (record.meta.status === 'draft') {
        return false;
      }
      return true
    }

    if (record.status === 'draft') {
      return false;
    }
    return true;
  },
  handler: async ({ record }) => {
    const path = `${apiBasePath}${record.id}/execute/`;
    
    let response = null;
    try {
      response = await request({
        path,
        method: 'POST',
      });
    } catch(e) {}

    if (response && response.ok) {
      return {
        notification: {
          type: 'success',
          message: 'documents.actions.send.successMessage'
        }
      }
    }

    return {
      notification: {
        type: 'error',
        message: 'documents.actions.send.failureMessage'
      }
    }
  }
})