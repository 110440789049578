import React, { Component, Fragment } from 'react';
import { Title } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import LastDocumentsWidget from '../../../../components/LastDocumentsWidget';
// import DocumentsCountWidget from '../../../../components/DocumentsCountWidget';
// import CodesCountWidget from '../../../../components/CodesCountWidget';
// import ProductsCountWidget from '../../../../components/ProductsCountWidget';
import LastNotificationsWidget from '../../../../components/LastNotificationsWidget';
import DocumentsMetricsWidget from '../../../../components/DocumentsMetricsWidget';
import CodesMetricsWidget from '../../../../components/CodesMetricsWidget';
import appConfig from '../../../../appConfig';

const styles = {
    root: {
        marginTop: '1em',
    }
};

class Dashboard extends Component {
    state = {};

    render() {

        const enableMetrics = appConfig.data.ui.enableMetrics;
        const enableNotifications = appConfig.data.ui.enableNotifications;

        return (
            <Fragment>
                <Title title={'common.dashboard'} />

                <div style={styles.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4} spacing={2}>
                            <Grid container item spacing={2}>
                                <Grid item xs={12} >
                                    <LastDocumentsWidget showProductGroups />
                                </Grid>
                                {enableNotifications && (
                                    <Grid item xs={12} >
                                        <LastNotificationsWidget />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={6} lg={8} spacing={2} alignContent="flex-start">
                            {/* <Grid container item spacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <DocumentsCountWidget />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <CodesCountWidget />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <ProductsCountWidget />
                                </Grid>
                            </Grid> */}
                            {enableMetrics && (
                                <Grid container item spacing={2}>
                                    <Grid item xs={12} >
                                        <DocumentsMetricsWidget />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <CodesMetricsWidget />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </Fragment>
        );
    }
}

export default Dashboard;
