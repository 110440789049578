import React from 'react';
import ResourceIcon from '@material-ui/icons/FolderShared';
import { ArrayInput, SimpleFormIterator, TextInput } from '../../../../components/Admin';

const name = 'settings/userRole';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/cm/company/role/',
  },
  itemToString: x => x.name || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        validation: {
          required: true,
        }
      },
      description: {
        type: 'string',
        options: {
          multiline: true,
        }
      },
      permissions: {
        type: 'array',
        validation: {
          required: true,
        },
        componentInput: (props) => (
          <ArrayInput {...props} fullWidth source="permissions">
              <SimpleFormIterator>
                <TextInput source="permission" />
              </SimpleFormIterator>
          </ArrayInput>
        ),
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  showInMenu: true,
  group: 'settings',
  createFields: [
    'name',
    'description',
    'permissions',
  ],
  editFields: [
    'id',
    'name',
    'description',
    'permissions',
  ],
  showFields: [
    'id',
    'name',
    'description',
    'permissions',
  ],
  listFields: [
    {name: 'id'},
    'name',
    'description',
  ],
  listFilters: [],
};