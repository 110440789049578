import React from 'react';
import documentRowStyle from '../ui/documentRowStyle';
import GuidField from '../../components/GuidField';
import documentStatus from '../../services/marking-service/enums/documentStatus';
import docImportActionCreator from './docImportActionCreator';
import docSendBulkActionCreator from './docSendBulkActionCreator';
import docSendActionCreator from './docSendActionCreator';
import docShowCodesActionCreator from './docShowCodesActionCreator';
import docShowLogsActionCreator from './docShowLogsActionCreator';
import documentDirectionType from '../../services/marking-service/enums/documentDirectionType';
import docUpdateActionCreator from './docUpdateActionCreator';
import documentExternalStatus from '../../services/marking-service/enums/documentExternalStatus';
import docRenewActionCreator from './docRenewActionCreator';
import docDownloadJSONActionCreator from './docDownloadJSONActionCreator';
import docUpdateBulkActionCreator from './docUpdateBulkActionCreator';
import ChildGeneratorSection from '../../components/ChildGeneratorSection';
import createDocWithGenerator from '../../services/marking-service/modules/createDocWithGenerator';


export default (config) => {
  let {
    // General
    name,
    icon,
    itemToString = x => (x.extra && x.extra.name) || `#${x.id}`,
    apiResolve = {},
    allowList = true,
    allowCreate = true,
    allowEdit = true,
    allowShow = false,
    allowDelete = true,
    allowClone = true,
    showInMenu = true,
    allowCreateWithGenerator = true,
    hasConnectedCodes = true,
    schema = null,
    listDefaultSort = { field: 'created', order: 'DESC' },
    listFields = [],
    listFilters = [],
    listActions = [],
    bulkActions = [],
    itemActions = [],
    listRowActions = [],
    showActions = [],
    editActions = [],
    createFields = [],
    editFields = [],
    listRowStyle = documentRowStyle,

    // Custom for documents
    apiBasePath,
    allowSendAction = true,
    allowUpdateAction = false,
    allowRenewAction = true,
    allowShowLogsAction = true,
    allowShowCodesAction = true,
    allowDocumentsImport = true,
    precessName,
    showExternalId = true,
    showExternalStatus = true,
    showDirection = false,
    tabStatusLabel = 'documents.tabs.documentStatus',
    tabInfoLabel = 'documents.tabs.documentInfo',
    tabChildrenLabel = 'documents.tabs.documentChildren',
    editTabStatusFields = null,
    childResource = null,
    childAddButton = null,

    ...rest
  } = config;

  apiResolve = {
    path: apiBasePath,
    ...apiResolve,
  }
  apiResolve.mapping = {
    outputItem: ({
      data,
      extra,
      children_generator,
      children,
    }) => ({
      data,
      extra,
      children_generator: children_generator ? [children_generator] : undefined,
      children,
    }),
    inputItem: ({ document_id, ...data }) => ({ id: document_id, ...data }),
    ...(apiResolve.mapping || {}),
  }

  if (listFields.length === 0) {
    listFields = [
      {
        name: 'id',
        component: GuidField,
      },
      {
        name: 'created',
        type: 'dateTime',
        sortable: true,
      },
      {
        name: 'status',
        component: documentStatus.Field,
      },
      {
        name: 'extra.name',
        type: 'string',
      },
    ];
    if (showDirection) {
      listFields.push({
        name: 'direction',
        type: 'string',
        component: documentDirectionType.Field,
      });
    }
    if (showExternalId) {
      listFields.push({
        name: 'external_id',
        type: 'string',
        component: GuidField,
      });
    }
    if (showExternalStatus) {
      listFields.push({
        name: 'external_status',
        type: 'string',
        component: documentExternalStatus.Field,
      });
    }
  }


  listFilters = [
    ...listFilters,
    { name: 'status', component: documentStatus.Input, alwaysOn: true },
    'external_id',

  ];

  // if (showExternalStatus) {
  //   listFilters.push('external_id');
  //   listFilters.push('external_status');
  // }

  if (createFields.length === 0) {
    createFields = Object.keys(schema.properties);
  }

  if (editFields.length === 0) {
    editFields = {
      type: 'tabbed',
      items: [{
        type: 'tab',
        label: tabStatusLabel,
        items: [
          { name: 'extra.name', label: 'documents.fields.extra.name' },
          { name: 'id', editable: false },
          { name: 'created', type: 'dateTime', editable: false },
          { name: 'status', component: documentStatus.Field, editable: false },
        ],
      },{
        type: 'tab',
        label: tabInfoLabel,
        items: editTabStatusFields || createFields || []
      }]
    };
    if (showExternalId) {
      editFields.items[0].items.push({ name: 'external_id', type: 'string', editable: false });
    }
    if (showExternalStatus) {
      editFields.items[0].items.push({ name: 'external_status', type: 'string', component: documentExternalStatus.Field, editable: false });
    }
    if (childResource !== null) {
      editFields.items.push({
        type: 'tab',
        label: tabChildrenLabel,
        items: [{
          options: {
            fullWidth: true,
          },
          component: (props) => (
            <childResource.ManyField
              {...props}
              fullWidth
              target="parent_id"
              createButton={childAddButton || childResource.AddByParentButton}
            />
          )
        }]
      })
    }
  }

  if (allowSendAction && apiBasePath) {
    itemActions.push(docSendActionCreator({ apiBasePath }));
    bulkActions.push(docSendBulkActionCreator({ apiBasePath }));
  }
  if (allowUpdateAction && apiBasePath) {
    itemActions.push(docUpdateActionCreator({ apiBasePath }));
    bulkActions.push(docUpdateBulkActionCreator({ apiBasePath }));
  }
  if (allowRenewAction && apiBasePath) {
    itemActions.push(docRenewActionCreator({ apiBasePath }));
  }
  if (allowShowCodesAction) {
    itemActions.push(docShowCodesActionCreator());
  }
  if (allowShowLogsAction && precessName) {
    itemActions.push(docShowLogsActionCreator({ precessName }));
  }

  if (allowDocumentsImport && precessName) {
    listActions.push(
      docImportActionCreator({ docType: precessName })
    )
  }

  itemActions.push(docDownloadJSONActionCreator({ apiBasePath, order: 10000 }));

  if (allowCreateWithGenerator) {
    // TODO: support tabbes
    if (Array.isArray(createFields)) {
      createFields = [
        ...createFields,
        {
          name: 'children_generator',
          component: ChildGeneratorSection,
        }
      ];
    }
  }

  if (hasConnectedCodes) {
    itemActions.push({
      name: 'documents.actions.createNextDoc',
      isDisabled: ({ record }) => record.status === 'draft',
      getTransitionUrl: ({ record }) => createDocWithGenerator.getTransitionUrl({
        params: {
          document_id: record.id,
        }
      }),
    })
  }

  const result = {
    name,
    icon,
    itemToString,
    apiResolve,
    allowList,
    allowCreate,
    allowEdit,
    allowShow,
    allowDelete,
    allowClone,
    allowCreateWithGenerator,
    hasConnectedCodes,
    showInMenu,
    schema,
    listDefaultSort,
    listFields,
    listFilters,
    listActions,
    bulkActions,
    itemActions,
    listRowActions,
    showActions,
    editActions,
    createFields,
    editFields,
    listRowStyle,
    isDocument: true,

    ...rest
  };

  return result;
}
