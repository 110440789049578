import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Product |||| Products',
      actions: {
        loadProducts: {
          name: 'Load products',
          successMessage: 'Request sent',
          failureMessage: 'Request failed',
        },
      },
      fields: {
        name: 'Name',
        gtin: 'GTIN',
        'data.brand': 'Brand',
        'data.fullName': 'Full name',
        'tn_ved_code': 'TN VED code',
        'data.inn': 'INN',
      },
    }
  }
}