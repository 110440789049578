import ResourceIcon from '@material-ui/icons/Subject';
import GuidField from '../../../../components/GuidField';
import LogField from '../../../../components/LogField';

const name = 'common/logs';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/logs/logs/',
    mapping: {
      inputItem: ({ message = '', ...data }) => {
        let shortMessage = message;
        const requestMatch = message.match(/"method": "([^"]+)", "url": "([^"]+)"/);
        if (requestMatch !== null) {
          shortMessage = `${requestMatch[1]} ${requestMatch[2]}`;
        }
        // Also if url too long
        if (shortMessage.length > 200) {
          shortMessage = `${shortMessage.slice(0, 190)}...`;
        }

        return {
          shortMessage,
          message,
          ...data
        };
      }
    },
  },
  itemToString: x => `#${x.id}`,
  schema: {
    type: 'object',
    properties: {

    }
  },
  allowList: true,
  allowCreate: false,
  allowEdit: false,
  allowShow: true,
  allowDelete: false,
  showInMenu: true,
  showFields: [
    'id',
    'level',
    'task_id',
    'request_id',
    { name: 'datetime', type: 'dateTime' },
    'args',
    { name: 'message', component: LogField, options: { fullWidth: true }},
  ],
  listFilters: [
    'task_id',
  ],
  listDefaultSort: { field: 'datetime', order: 'DESC' },
  listFields: [
    { name: 'id', component: GuidField },
    'level',
    { name: 'task_id', component: GuidField },
    { name: 'datetime', type: 'dateTime' },
    'shortMessage',
  ],
};
