import SettingsIcon from '@material-ui/icons/Album';
import locales from './locales';
import uiConfigurationPage from './pages/uiConfigurationPage';

import company from './resources/company';
import user from './resources/user';
import logs from './resources/logs';

const resources = [
  company,
  user,
  logs,
];

export default {
  name: 'common',
  locales,
  resources,
  pages: [
    uiConfigurationPage,
  ],
  icon: SettingsIcon,
};
