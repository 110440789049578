import resourceConfig from '../config';

export default {
  pages: {
    [resourceConfig.name]: {
      name: 'Company credentials',
      fields: {
        unique_string: 'Certificate ID',
        oms_id: 'OMS ID',
        oms_connection_id: 'OMS Connection ID',
        client_token: 'OMS device token',
      }
    }
  }
}
