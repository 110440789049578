import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Задача |||| Задачи',
      actions: {
        showLogs: 'Показать логи',
        cancel: {
          name: 'Отменить',
          confirmMessage: 'Отмена задачи не гарантирует завершения процесса. Продолжить?',
          requestCanceledMessage: 'Запрос отменен',
          successMessage: 'Запрос обработан успешно',
          failureMessage: 'Запрос обработался с ошибкой',
        },
      },
      fields: {
        request: 'Данные запроса',
        response: 'Данные ответа',
        document_process: 'Тип документа',
        document_id: 'ID документа',
        status: 'Статус',
        finished: 'Дата завершение',
      }
    }
  }
}
