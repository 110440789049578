import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Scheduled task |||| Scheduled tasks',
      actions: {
        execute: {
          name: 'Run task',
          successMessage: 'Run task request success',
          failureMessage: 'Run taks request failed',
        },
      },
      fields: {
        name: 'Name',
        task_name: 'Task',
        enabled: 'Enabled',
        data: 'Task params (JSON)',
        crontab: 'Crontab (JSON)',
        last_run_at: 'Last run',
        total_run_count: 'Total run count',
        one_off: 'Single run',
        'meta.product_group': 'Product group',
      },
    }
  }
}
