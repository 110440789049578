export default {
  services: {
    company: {
      name: 'Company',
      groups: {
        settings: 'Settings',
        reports: 'Reports',
      }
    }
  }
}