import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  translate as translateRA,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from "react-admin";
import { withRouter, Link } from "react-router-dom";
import Button from "../Button";
import compose from "../../utils/compose";
import TransitionLink from "../TransitionLink";

@compose(
  translateRA,
  withRouter,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  })
)
class ResourceActionButton extends PureComponent {
  state = {
    loading: false,
  };

  handleAction = async () => {
    if (this.state.loading) {
      return;
    }

    const { action, showNotification, refreshView, ...props } = this.props;

    this.setState({
      loading: true,
    });
    const result = await action.handler(props);

    if (result && result.notification) {
      showNotification(result.notification.message, result.notification.type);
    }

    this.setState(
      {
        loading: false,
      },
      refreshView
    );
  };

  render() {
    let {
      action,
      disabled = false,
      record = null,
      translate,
      location,
      match,
    } = this.props;
    const { loading } = this.state;

    const nextProps = {};

    if (
      action.isDisabled &&
      typeof action.isDisabled === "function" &&
      record !== null
    ) {
      disabled = action.isDisabled({ record });
    }

    if (action.getUrl && record !== null) {
      nextProps.component = Link;
      nextProps.to = disabled ? null : action.getUrl({ record, location, match });
    } else if (action.getTransitionUrl && record !== null) {
      nextProps.component = TransitionLink;
      nextProps.transitionTo = disabled ? null : action.getTransitionUrl({ record });
    }

    return (
      <Button
        {...nextProps}
        size="small"
        color="default"
        onClick={action.handler && this.handleAction}
        loading={loading}
        disabled={disabled}
      >
        {translate(action.name)}
      </Button>
    );
  }
}

export default ResourceActionButton;
