import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        '1': 'Shoes',
        '3': 'Tobacco (blocks)',
        '4': 'Tobacco (packs)',
        '5': 'Medicines for medical use',
        '20': 'Milk',
        '7': 'Tires and tires pneumatic rubber new',
        '8': 'Photo Cameras (excluding cinematographic cameras), photo flashes and flash lamps',
        '9': 'Perfume and toilet water',
        '10': 'Garments, bed linen, table linen, toilet and kitchen linen',
        '11': 'Bicycles and bicycle frames',
        '12': 'Stroller armchairs',
        '14': 'Alternative Tobacco Products (GS1)',
        '15': 'Alternative tobacco products (non GS1)',
        '16': 'Packaged water',
      },
    }
  }
}
