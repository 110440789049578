import { SimpleShowLayout } from '../../components/Admin';
import ResourceListPage, { ResourceListFilter } from '../../components/ResourceListPage';
import getFieldsFromSchema from './getFieldsFromSchema';


export default (options = {}) => {
  let {
    schema = null,
    listFields = ['id'],
    expand = undefined,
    listActions = undefined,
    listFilters = undefined,
    listExtendedFilters = undefined,
    listRowActions = undefined,
    listRowClickAction = undefined,
    listDefaultSort = undefined,
    listExpandFields = undefined,
    listRowStyle = undefined,
    bulkActions = undefined,
    allowCreate = false,
    allowEdit = false,
    allowShow = false,
    allowDelete = false,
    allowList = false,
    allowClone = false,
    allowExport = true,
  } = options;

  if (!allowList || schema === null) {
    return null;
  }

  let filters = undefined;
  if (listFilters && listFilters.length > 0) {
    filters = getFieldsFromSchema({
      schema,
      editable: true,
      fields: listFilters,
      Component: ResourceListFilter,
    })
  }
  let extendedFilters = undefined;
  if (listExtendedFilters && listExtendedFilters.length > 0) {
    extendedFilters = getFieldsFromSchema({
      schema,
      editable: true,
      fields: listExtendedFilters,
      Component: ResourceListFilter,
    });
  }

  if (expand === undefined && listExpandFields !== undefined) {
    expand = (props) => (
      getFieldsFromSchema({
        schema,
        fields: listExpandFields,
        Component: SimpleShowLayout,
        ...props
      })
    )
  }

  return (props) => (
    getFieldsFromSchema({
      useFor: 'LIST',
      schema,
      fields: listFields,
      Component: ResourceListPage,
      allowCreate,
      allowEdit,
      allowShow,
      allowDelete,
      allowClone,
      allowExport,
      filters,
      extendedFilters,
      expand,
      defaultSort: listDefaultSort,
      actions: listActions,
      rowActions: listRowActions,
      rowClick: listRowClickAction,
      rowStyle: listRowStyle,
      bulkActions,
      ...props
    })
  )
}
