import React, { PureComponent } from 'react';
import {
  Edit,
  TopToolbar,
  Toolbar,
  SaveButton,
  SimpleForm,
  TabbedForm,
} from 'react-admin';
import { commonStyles } from '../../theme';
import ResourceActionButton from '../ResourceActionButton';
import checkPermissions from '../../utils/checkPermissions';
import NotFoundPage from '../../pages/NotFoundPage';

const EditActions = ({ actions, data }) => (
  <TopToolbar>
    {actions && actions.map(action => (
      <ResourceActionButton key={action.name} action={action} record={data} />
    ))}
  </TopToolbar>
);

const EditToolbar = ({ redirectUrl = null, ...props }) => (
  <Toolbar
    {...props}
    style={commonStyles.toolbar}
  >
    <SaveButton
      style={commonStyles.toolbarButton}
      label="actions.save"
      redirect={redirectUrl || 'list'}
      submitOnEnter={true}
    />
    {redirectUrl === null && (
      <SaveButton
        style={commonStyles.toolbarButton}
        label="actions.saveAndGoToEdit"
        redirect={'edit'}
        submitOnEnter={false}
        variant="text"
        color="default"
      />
    )}
  </Toolbar>
);

class ResourceEditPage extends PureComponent {
  render() {
    let {
      children,
      withTabs = false,
      getOptions = null,
      redirectUrl = null,
      actions = null,
      permissions,
      ...restProps
    } = this.props;

    const FormComponent = withTabs ? TabbedForm : SimpleForm;

    if (getOptions !== null) {
      const options = getOptions(this.props);
      if (options.error) {
        return options.error;
      }
      if (options.additionalValues) {

      }
      if (options.redirectUrl) {
        redirectUrl = options.redirectUrl;
      }
    }

    if (permissions === undefined) {
      return null; // LOADING
    }

    if (!checkPermissions('edit', permissions)) {
      return (
        <NotFoundPage />
      )
    }

    return (
      <Edit
        {...restProps}
        undoable={false}
        actions={<EditActions actions={actions} />}
      >
        <FormComponent
          variant="standard"
          margin="normal"
          redirectUrl={redirectUrl || undefined}
          toolbar={<EditToolbar redirectUrl={redirectUrl || undefined} />}
        >
          {children}
        </FormComponent>
      </Edit>
    );
  }
}

export default ResourceEditPage;