export const getCusrorFromUrl = (urlString) => {
  if (urlString) {
    const url = new URL(urlString);
    if (url && url.searchParams.has('cursor')) {
      return url.searchParams.get('cursor')
    }
  }

  return null;
}

export const getPagingDataFromResponse = (response, paginationParams = {}) => {
  const {
    page = null,
    perPage = null,
  } = paginationParams;
  const result = {
    data: [],
    limit: perPage,
    offset: perPage === null ? null : perPage * (page - 1),
    total: null,
    hasNext: false,
    hasPrevious: false,
    nextCursor: null,
    previousCursor: null,
  };

  if (response.ok && response.data) {
    if (Array.isArray(response.data)) { // Если API возвращает сразу массив, без пейджинга
      result.data = response.data;
      result.total = response.data.length;
    } else if (Array.isArray(response.data.results)) {
      result.data = response.data.results;
      if (response.data.count) {
        result.total = response.data.count;
      } else {
        if (response.data.next) {
          result.nextCursor = getCusrorFromUrl(response.data.next);
        }
        if (response.data.previous) {
          result.previousCursor = getCusrorFromUrl(response.data.previous);
        }
      }
      if (response.data.next) {
        result.hasNext = true;
      }
      if (response.data.previous) {
        result.hasPrevious = true;
      }
    }
  }

  // TODO: это костыль для пагинации без каунта, надо переделывать на уровне листа
  if (result.total === null && result.limit !== null && result.offset !== null) {
    result.total = result.offset + result.data.length;
    if (result.hasNext) {
      // Приходится добавлять еще один item в тотал чтобы работал пейджинг
      result.total += 1;
    }
  }

  return result;
}
