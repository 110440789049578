import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';

const name = 'marking/usage-report-child';
const parentResourceName = 'marking/usage-report';
const apiBasePath = '/bp/processes/usage_report/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.code': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
    }
  },
  listFields: [
    'data.code',
  ],
});
