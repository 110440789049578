import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Отчет о нанесении',
      fields: {
        'data.usage_type': 'Тип использования',
        'data.accompanying_document': 'Производственный ветеринарный сопроводительный документ',
        'data.capacity': 'Объем/Масса',
        'data.exp_date': 'Срок годности (Дата)',
        'data.exp_datetime': 'Срок годности (Дата и время)',
        'data.used_in_production': 'Признак использовании КМ на производстве',
      }
    }
  }
}
