import deepmerge from 'deepmerge';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import en from './langs/en';
import ru from './langs/ru';
import textCaseHelper from '../utils/textCaseHelper';


export const defaultLang = 'ru';
let langs = {
  en,
  ru,
}

export const registerLocales = (locales) => {
  // Quick fix for forcedCaseName in resource
  for (let lang in locales) {
    if ('resources' in locales[lang]) {
      for (let resource in locales[lang].resources) {
        if (!('forcedCaseName' in locales[lang].resources[resource])) {
          locales[lang].resources[resource].forcedCaseName = locales[lang].resources[resource].name;
        }
      }
    }
  }
  langs = deepmerge(langs, locales);
}

export const getLang = (lang) => {
  if (lang in langs) {
    return langs[lang];
  }
  return langs[defaultLang];
}

export const getLangFromStorage = () => {
  const lang = localStorage.getItem('lang');
  if (lang) {
    return lang;
  }
  return defaultLang
}

export const setLangToStorage = (lang) => {
  localStorage.setItem('lang', lang);
}

const getKeyLastPart = (key) => {
  if (typeof key !== 'string') {
    return key;
  }
  const parts = key.split(/[./]/).filter(x => x.length !== 0);
  if (parts.length === 0) {
    return key;
  }
  return parts[parts.length - 1];
}

export const pretifyUntranslatedKey = (key) => {
  if (typeof key !== 'string') {
    return key;
  }
  const lastPart = getKeyLastPart(key);
  return textCaseHelper.titleCase(lastPart);
}

const polyglotOptions = {
  onMissingKey: (key, options, lang) => {
    const lastPart = getKeyLastPart(key);
    if (lang in langs && langs[lang].common && lastPart in langs[lang].common) {
      return langs[lang].common[lastPart];
    }

    return pretifyUntranslatedKey(key);
  }
}

export const getI18nProvider = () => {
  const provider = polyglotI18nProvider(getLang, getLangFromStorage(), polyglotOptions);

  const originalTranslate = provider.translate;
  provider.translate = (key, options, ...other) => {
    if (options && options._) {
      // Удаляем дефолтные переводы от ra-core чтобы вызывался onMissingKey
      delete options._;
    }
    return originalTranslate(key, options, ...other);
  }

  return provider;
}
