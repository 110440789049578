import request from '../../network/request';

export default ({ apiBasePath }) => ({
  name: 'documents.actions.refresh.name',
  isDisabled: ({ record }) => record.external_id === null || record.status === 'request_created',
  handler: async ({ record }) => {
    const path = `${apiBasePath}${record.id}/update/`;
    
    let response = null;
    try {
      response = await request({
        path,
        method: 'POST',
      });
    } catch(e) {}

    if (response && response.ok) {
      return {
        notification: {
          type: 'success',
          message: `documents.actions.refresh.successMessage`
        }
      }
    }

    return {
      notification: {
        type: 'error',
        message: `documents.actions.refresh.failureMessage`
      }
    }
  }
})